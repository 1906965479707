import { lazy } from 'react';

/**
 * 路由懒加载
 * @param {String} filename 文件路径
 */
const lazyRouter = (filename: string) => {
  return lazy(() => import(`../pages/${filename}`))
}

export const RouteMap = [
  { path: '/', name: 'Home', title: '上门签到', notMenu: true, component: lazyRouter('Home/Index')},
  { path: '/SignIn', name: 'SignIn', title: '上门签到', notMenu: true, component: lazyRouter('SignIn/SignIn')},
  { path: '/SignInResult', name: 'SignInResult', title: '上门签到', notMenu: true, component: lazyRouter('SignInResult/SignInResult')},
  { path: '/Evaluation', name: 'Evaluation', title: '服务评价', notMenu: true, component: lazyRouter('Evaluation/Evaluation')},
  { path: '/ImgPage', name: 'ImgPage', title: '服务评价', notMenu: true, component: lazyRouter('ImgPage/ImgPage')},
  { path: '/SignList', name: 'SignList', title: '签到列表', notMenu: true, component: lazyRouter('SignList/SignList')},
  { path: '/AssetSelect', name: 'AssetSelect', title: '资料填写', notMenu: true, component: lazyRouter('AssetSelect/AssetSelect')},
  { path: '/Identity', name: 'Identity', title: '身份认证', notMenu: true, component: lazyRouter('Identity/Identity')},
  { path: '/Result', name: 'Result', title: '资料填写', notMenu: true, component: lazyRouter('Result/Result')},
  { path: '/FormPage', name: 'FormPage', title: '资料填写', notMenu: true, component: lazyRouter('FormPage/FormPage')},
  
]

let routeList: any[] = []
let neatenRouteMap = (list: any[]) => {
  list.forEach((item: any) => {
    if (item.children && item.children.length) {
      neatenRouteMap(item.children)
    } else {
      routeList.push(item)
    }
  })
}
neatenRouteMap(RouteMap)

export const RouteList = routeList
// 登录后默认跳转的地址
export const loginToHome = '/'
