import React from 'react'
import { useHistory } from 'react-router-dom'
import PrivateRoute from '../../components/privateRoute/Index'
import { RouteMap } from '../../route/Index'

const Layout = () => {
  let history = useHistory()
  RouteMap.some((item: any) => {
    if (item.path === history.location.pathname) {
      document.title = item.title
      return true
    }
  })
  return (
    <div className="height100">
      <PrivateRoute />
    </div>
  )
}

export default Layout
