import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import { ConfigProvider } from 'antd';
// import zh_CN from 'antd/es/locale/zh_CN';
// import 'moment/locale/zh-cn';

ReactDOM.render(
  // <ConfigProvider locale={zh_CN}>
    <App />,
  // </ConfigProvider>,
  document.getElementById('root')
);

